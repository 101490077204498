import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from 'Context/user';

import { ALLOWED_STORE_KEY, ATTRIBUTION_KEYS_REGEX, EXCLUDE_ATTRIBUTION_KEY_PAIRS, GA_VARIABLES, getUTMFromStorage, saveUTMToStorage, SNOWPLOW_TRIGGER } from 'Utilities/analytics';

export const useUTM = () => {
  const { userActions: { update: updateUser } } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    // Make utm params available to GTM
    const queryParams = Object.fromEntries(new URLSearchParams(history.location.search));
    const isExcludedParamsNotAvailable = EXCLUDE_ATTRIBUTION_KEY_PAIRS.some((excludeKeyPairs) => {
      const excludeSet = new Set(excludeKeyPairs.flat());
      const querySet = new Set(Object.entries(queryParams).flat());
      return (excludeSet.difference(excludeSet.intersection(querySet)).size > 0);
    });
    const qsArray = isExcludedParamsNotAvailable
      ? Object.entries(queryParams)
        .filter(([k]) => ATTRIBUTION_KEYS_REGEX.test(k))
        .filter(([, v]) => v)
        .map(([k, v]) => [k?.toLowerCase(), v])
        .sort((a, b) => a?.[0].localeCompare(b?.[0])) || []
      : [];

    const previousUTMParams = getUTMFromStorage() || {};

    let previousVisitTime;
    let utmParamsFromStore = [];

    if (Array.isArray(previousUTMParams)) {
      utmParamsFromStore = previousUTMParams;
    } else if (previousUTMParams?.qsArray) {
      previousVisitTime = previousUTMParams?.time;
      utmParamsFromStore = previousUTMParams?.qsArray;
    }

    const hasAttributionQueryParams = (qs) => Array.isArray(qs) && qs.length;

    const TIME_NOW = new Date().getTime();

    const snowplowTimeDelta = (previousTime = previousVisitTime) => {
      if (Number.isInteger(previousTime)) {
        if (TIME_NOW - previousTime >= 5000) {
          SNOWPLOW_TRIGGER('newSession');
        }
      } else {
        SNOWPLOW_TRIGGER('newSession');
      }
    };

    // Don't fire a new session if `onSessionUpdateCallback` has been called within the last 5 seconds
    // eslint-disable-next-line no-underscore-dangle
    if (!globalThis.__SP_SESSION_TIME__ || TIME_NOW - globalThis.__SP_SESSION_TIME__ >= 5000) {
      if (hasAttributionQueryParams(qsArray) && hasAttributionQueryParams(utmParamsFromStore)) {
        if (JSON.stringify(qsArray) !== JSON.stringify(utmParamsFromStore)) {
          // Fire new session if utm params have changed after 5 secs
          snowplowTimeDelta();
        }
      } else if (Number.isInteger(previousVisitTime) && Array.isArray(qsArray) && Array.isArray(utmParamsFromStore)) {
        if (utmParamsFromStore?.length === 0 && hasAttributionQueryParams(qsArray)) {
          // Fire new session if utm params have been added after 5 secs from previous visit with no UTMs
          snowplowTimeDelta();
        }/* else if (qsArray?.length === 0 && hasAttributionQueryParams(utmParamsFromStore)) {
          // Fire new session if utm params have been removed after 5 secs from previous visit with UTMs
          snowplowTimeDelta();
        } */
      }
    }

    saveUTMToStorage(qsArray);

    (qsArray || utmParamsFromStore)?.forEach(([k, v]) => {
      if (Object.values(ALLOWED_STORE_KEY).includes(v)) {
        GA_VARIABLES[k] = v;
      }
    });

    const isReferredUser = [...qsArray, ...utmParamsFromStore]
      .some(([k, v]) => k === 'utm_source' && v === ALLOWED_STORE_KEY.MENTON_ME);

    updateUser({ isReferredUser });
  }, [history]);
};
