import { safeLocalStorage } from '../storages';

export const UTM_KEY = 'utm_params';

export const ALLOWED_STORE_KEY = {
  CONFUSED: 'confused',
  MENTON_ME: 'mentionme',
};

export const ATTRIBUTION_KEYS_REGEX = /^(utm_(source|medium|campaign|term|content)|gclid|DCLID|MSCLKID)$/i;

export const saveUTMToStorage = (qsArray) => {
  safeLocalStorage.setItem(UTM_KEY, {
    qsArray,
    time: new Date().getTime(),
  });
};

export const getUTMFromStorage = () => safeLocalStorage.getItem(UTM_KEY);

export const loginEmailUTMParams = [
  ['utm_medium', 'email'],
  ['utm_source', 'trxn'],
];

export const EXCLUDE_ATTRIBUTION_KEY_PAIRS = [
  loginEmailUTMParams,
];
